.input-container {
	width: 100%;
	border-radius: 10px;
	display: flex;
	align-items: center;
	gap: 2rem;
	background-color: #ffffff34;

	input {
		width: 90%;
		height: 60%;
		background-color: transparent;
		border: none;
		padding-left: 1rem;
		font-size: 1.2rem;

		&::selection {
			background-color: #9a86f3;
		}

		&:focus {
			outline: none;
		}
	}

	button {
		padding: 0.3rem 2rem;
		border-radius: 10px;
		background-color: #9a86f3;
		border-color: #9a86f3;
	}
}