* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
     }
     
     body,
     button,
     input {
	font-family: "Josefin Sans", sans-serif;
     }
     
     body {
	max-height: 100vh;
	max-width: 100vw;
	background-color: #ccccdf;
     }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
