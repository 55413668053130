.roomchat-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	align-items: center;
	margin-top: 90px;

	form {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		background-color: #1ba38c76;
		border-radius: 2rem;
		padding: 3rem 5rem;
	}
}

/* .register-login-container {
	

		.register-heading {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		button {
			background-color: #20a532;
			color: white;
			padding: 1rem 2rem;
			border: none;
			cursor: pointer;
			border-radius: 0.4rem;
			font-size: 1rem;
		}
	}
} */