.allUsers-container {
	display: grid;
	grid-template-rows: 10% 90%;
	overflow: hidden;

	.heading {
		padding: 20px;
	}

	.users-detail-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: auto;
		gap: 0.8rem;

		&::-webkit-scrollbar {
			width: 0.2rem;

			&-thumb {
				background-color: #ffffff39;
				width: 0.1rem;
				border-radius: 1rem;
			}
		}
	}

	.user {
		background-color: #ffffff34;
		min-height: 5rem;
		cursor: pointer;
		width: 90%;
		border-radius: 10px;
		padding: 0.4rem;
	}

	.selected {
		background-color: #56da61;
	}
}