.chatbox-container {
	display: grid;
	grid-template-rows: 10% 80% 10%;
	gap: 0.1rem;
	overflow: hidden;

	.chatbox-header {
		background-color: #56da61;
		border-radius: 12px;
		display: flex;
		align-items: center;
		gap: 1rem;
		padding: 2rem;
		z-index: 2;
	}

	.chatbox-body {
		padding: 1rem 2rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0.2rem;

			&-thumb {
				background-color: #ffffff39;
				width: 0.1rem;
				border-radius: 1rem;
			}
		}

		.message {
			display: flex;
			align-items: center;

			.content {
				max-width: 40%;
				overflow-wrap: break-word;
				padding: 1rem;
				font-size: 1.1rem;
				border-radius: 1rem;
				color: #d1d1d1;
			}
		}

		.sent {
			justify-content: flex-end;

			.content {
				background-color: #005C4B;
			}
		}

		.recieved {
			justify-content: flex-start;

			.content {
				background-color: #202C33;
			}
		}
	}
}