.oneToOneChat-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	align-items: center;
	margin-top: 20px;

	.users-chat-container {
		height: 85vh;
		width: 85vw;
		background-color: rgba(27, 163, 140, 0.462745098);
		display: grid;
		grid-template-columns: 25% 75%;
		border-radius: 1rem;

		.before-selection {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}